import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import './SalonGrid.scss';

const SalonGridItem = ({ salon }) => (
	<li className='salon-item'>
		<Link to={`${salon.handle}`}>
			<div className='salon-item__img'>
				{salon.image ? (
					<LazyLoadImage
						src={salon.image}
						alt={salon.name}
						effect='blur'
						wrapperClassName='lazyLoad-wrapper-img'
					/>
				) : (
					<LazyLoadImage
						src='/images/empty-expert.png'
						alt='empty'
						effect='blur'
						wrapperClassName='lazyLoad-wrapper-img'
					/>
				)}
			</div>
			<h3 className='salon-item__name'>{salon.name}</h3>
			<span className='salon-item__address'>{salon.address}</span>
		</Link>
	</li>
);

export default SalonGridItem;
