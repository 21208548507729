export default [
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/files/Untitled_design_52.png?v=1718123742',
		title: 'Hair care products',
		handle: '/collections/care',
	},
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/collections/1_360x.jpg?v=1586353336',
		title: 'Hair colour',
		handle: '/collections/colour',
	},
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/collections/Image_3_3x_3c04cf84-391c-442a-83fe-eb6e68e57634_360x.png?v=1626188918',
		title: 'The Gloss and Glaze Collection',
		handle: '/collections/gloss-and-glaze',
	},
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/collections/1_776015a6-79c9-4982-aa5a-38523da68bf9.jpg?v=1674563857',
		title: 'Root touch up',
		handle: '/collections/root-touch-up',
	},
];
