import React from 'react';
import cn from 'classnames';
import Video from '../../../Video';
import Button from '../../../Button';

const SectionVideo = ({ blok }) => {
	const {
		title,
		text,
		buttonText,
		buttonLink,
		videoId,
		videoTitle,
		isLarge,
	} = blok;

	const classes = cn('video-section', {
		'video-section--large': isLarge,
	});

	return (
		<section className={classes}>
			<div className='video-section__info'>
				<h3>"{title}"</h3>
				<p>{text}</p>
				<Button
					isNotProduct
					buttonText={buttonText}
					isLink
					isDark
					isHalfWidth
					linkUrl={buttonLink}
				/>
			</div>
			<div className='    video-section__iframe'>
				<Video videoId={videoId} videoTitle={videoTitle} />
			</div>
		</section>
	);
};

export default SectionVideo;
