// External
import React, { useContext, useEffect, useState } from 'react';

// Internal
import ProductSelectionLevelOne from './ProductSelectionLevelOne';
import Button from '../../../../../components/Button';
import ProductSelectionLevelTwo from './ProductSelectionLevelTwo';
import ProductMiracleActions from './ProductMiracleActions';
import DeliveryRadio from '../../../../../components/DeliveryRadio';
import ProductRecommended from '../../ProductRecommended';

import {
	getMetafield,
	isAvailable,
	mergeArrays,
} from '../../../../../utils/helpers';
import { useWindowWidth } from '../../../../../utils/hooks';
import { StoreContext } from '../../../../../context/store-context';
import ProductSelectionGloss from './ProductSelectionGloss';
import ProductSelectionSmudge from './ProductSelectionSmudge';
import ProductSelectionBlending from './ProductSelectionBlending';

const ProductSelectionLevels = ({
	levelNumber,
	product,
	canSubscribe,
	activeRadio,
	setActiveRadio,
	sellingPlanGroup,
	selectedValue,
	setSelectedValue,
	options,
	discountPrice,
	recommendedProductHandle,
	recommendedTag,
	recommendedText,
	recommendedTextMobile,
	recommendedProduct,
	setRecommended,
	isRecommendedSelected,
	setRecommendedSelected,
	activeFirstLevel,
	setActiveFirstLevel,
}) => {
	
	const { setSlideProperties, allProducts } = useContext(StoreContext);
	const windowWidth = useWindowWidth();
	const isOneLevel = levelNumber === 1;
	const availableForSale = isAvailable(product);
	const isFilterActive = getMetafield(product, 'filter_active');
	const isMiracleSystemExpert = getMetafield(
		product,
		'is_miracle_system_expert'
	);
	const isMiracleSystemUltimate = getMetafield(
		product,
		'is_miracle_system_ultimate'
	);
	const fieldData =
		getMetafield(product, 'selection_first_level_list') &&
		getMetafield(product, 'selection_first_level_list').split('|');

	const getFirstLevelData = () => {
		if (!isOneLevel) {
			return allProducts
				.filter((item) => fieldData.includes(item.handle))
				.sort(
					(a, b) =>
						parseFloat(
							a.title.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/)
						).toFixed(1) -
						parseFloat(
							b.title.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/)
						).toFixed(1)
				);
		}
		return product.variants.filter(
			(item) => !item.title.toLowerCase().includes('miracle shot')
		);
	};

	const getFiltersArrays = (data) =>
		data &&
		data.map(
			(item) =>
				getMetafield(item, 'filter_type') &&
				getMetafield(item, 'filter_type').split('|')
		);
	const getFiltersList = (arrays) => mergeArrays(arrays);

	const [firstLevelData, setFirstLevelData] = useState(
		allProducts && getFirstLevelData()
	);
	const [filtersArrays, setFiltersArrays] = useState(
		firstLevelData && getFiltersArrays(firstLevelData)
	);
	const [filtersList, setFiltersList] = useState(
		filtersArrays && getFiltersList(filtersArrays)
	);
	const [activeSecondLevel, setActiveSecondLevel] = useState(null);
	const [secondLevelData, setSecondLevelData] = useState([]);
	const [activeThirdLevel, setActiveThirdLevel] = useState(null);
	const [thirdLevelData, setThirdLevelData] = useState([]);
	const [activeFourLevel, setActiveFourLevel] = useState(null);
	const [fourLevelData, setFourLevelData] = useState([]);
	const [activeFiveLevel, setActiveFiveLevel] = useState(null);
	const [fiveLevelData, setFiveLevelData] = useState([]);
	const [errorSelectLevel, setErrorSelectLevel] = useState({
		errorLevelOne: '',
		errorLevelTwo: '',
		errorLevelThree: '',
		errorLevelFour: '',
		errorLevelFive: '',
		errorSelect: '',
	});

	const levelValidator = {
		isValidOne: activeFirstLevel,
		isValidTwo: activeSecondLevel,
		isValidThree: activeThirdLevel,
		isValidFour: activeFourLevel,
		isValidFive: activeFiveLevel,
	};

	const optionsObjects = options.map((opt, i) => ({
		value: i,
		label: `Every ${opt} ${opt === '4 Weeks' ? '(most common)' : ''}`,
	}));

	useEffect(() => {
		setFirstLevelData(getFirstLevelData());
	}, [allProducts]);

	useEffect(() => {
		setFiltersArrays(getFiltersArrays(firstLevelData));
	}, [firstLevelData]);

	useEffect(() => {
		setFiltersList(getFiltersList(filtersArrays));
	}, [filtersArrays]);

	useEffect(() => {
		const currentVariants =
			activeFirstLevel &&
			getMetafield(activeFirstLevel, 'selection_second_level_list') &&
			getMetafield(activeFirstLevel, 'selection_second_level_list')
				.split('|')
				.map((item) => item.split(':')[1]);

		currentVariants &&
			setSecondLevelData(
				product.variants.filter(
					(item) =>
						item.storefrontId &&
						currentVariants.includes(
							item.storefrontId
								.replace('gid://shopify/Product/', '')
								.replace('gid://shopify/ProductVariant/', '')
						)
				)
			);
		setSlideProperties(activeFirstLevel);
	}, [activeFirstLevel]);

	useEffect(() => {
		setActiveSecondLevel(null);
		setActiveThirdLevel(null);
		setActiveFourLevel(null);
		setActiveFiveLevel(null);
	}, [activeFirstLevel]);

	useEffect(() => {
		setActiveThirdLevel(null);
		setActiveFourLevel(null);
		setActiveFiveLevel(null);
		activeSecondLevel &&
			setSlideProperties(activeSecondLevel);
	}, [activeSecondLevel]);

	useEffect(() => {
		const currentVariantsThree =
			activeFirstLevel &&
			getMetafield(activeFirstLevel, 'selection_third_level_list') &&
			getMetafield(activeFirstLevel, 'selection_third_level_list').split(
				'|'
			);

		if (currentVariantsThree && activeSecondLevel) {
			const titleShadeShot = activeSecondLevel.title
				.split('+')[1]
				.trim()
				.toLowerCase();
			const onlyGlossMatch = currentVariantsThree.filter((item) =>
				item.includes(titleShadeShot.split(' ')[0])
			);

			setThirdLevelData(
				allProducts.filter((item) =>
					onlyGlossMatch.includes(item.handle)
				)
			);
		}
	}, [activeSecondLevel]);

	const getCurrentVariants = (activeLevel, activeFirstLevel) => {
		if (
			activeLevel &&
			getMetafield(activeFirstLevel, 'selection_second_level_list')
		) {
			return getMetafield(activeFirstLevel, 'selection_second_level_list')
				.split('|')
				.map((item) => item.split(':')[1]);
		}
		return null;
	};

	const setVariantsData = (currentVariants, setData) => {
		if (currentVariants) {
			const variantsData = product.variants
				.filter(
					(item) =>
						item.storefrontId &&
						currentVariants.includes(
							item.storefrontId
								.replace('gid://shopify/Product/', '')
								.replace('gid://shopify/ProductVariant/', '')
						)
				)
				.slice(0, 1);

			setData(variantsData);
		}
	};

	useEffect(() => {
		const currentVariants = getCurrentVariants(
			activeThirdLevel,
			activeFirstLevel
		);
		setVariantsData(currentVariants, setFourLevelData);
	}, [activeThirdLevel]);

	useEffect(() => {
		const currentVariants = getCurrentVariants(
			activeFourLevel,
			activeFirstLevel
		);
		setVariantsData(currentVariants, setFiveLevelData);
	}, [activeFourLevel]);

	return (
		<div className='pdp-selection'>
			<div className='pdp-selection__desktop'>
				{availableForSale ? (
					<>
						<ProductSelectionLevelOne
							isFilterActive={isFilterActive}
							isOneLevel={isOneLevel}
							items={firstLevelData}
							filtersList={filtersList}
							activeFirstLevel={activeFirstLevel}
							setActiveFirstLevel={setActiveFirstLevel}
							errorSelectLevel={errorSelectLevel}
						/>
						{!isOneLevel && (
							<ProductSelectionLevelTwo
								isFilterActive={isFilterActive}
								items={secondLevelData}
								activeFirstLevel={activeFirstLevel}
								setActiveSecondLevel={
									setActiveSecondLevel
								}
								activeSecondLevel={activeSecondLevel}
								isOneLevel={isOneLevel}
								errorSelectLevel={errorSelectLevel}
							/>
						)}
						{(isMiracleSystemExpert === 'true' ||
							isMiracleSystemUltimate === 'true') && (
							<ProductSelectionGloss
								isFilterActive={isFilterActive}
								items={thirdLevelData}
								errorSelectLevel={errorSelectLevel}
								setActiveThirdLevel={setActiveThirdLevel}
								activeFirstLevel={activeFirstLevel}
								activeSecondLevel={activeSecondLevel}
								activeThirdLevel={activeThirdLevel}
								product={product}
							/>
						)}
						{isMiracleSystemUltimate === 'true' && (
							<>
								<ProductSelectionSmudge
									isFilterActive={isFilterActive}
									items={fourLevelData}
									errorSelectLevel={errorSelectLevel}
									setActiveFourLevel={
										setActiveFourLevel
									}
									activeThirdLevel={activeThirdLevel}
									activeFourLevel={activeFourLevel}
								/>

								<ProductSelectionBlending
									isFilterActive={isFilterActive}
									items={fiveLevelData}
									errorSelectLevel={errorSelectLevel}
									setActiveFiveLevel={
										setActiveFiveLevel
									}
									activeFourLevel={activeFourLevel}
									activeFiveLevel={activeFiveLevel}
								/>
							</>
						)}
						{isOneLevel ? (
							<>
								{recommendedProductHandle &&
									windowWidth >= 991 && (
										<ProductRecommended
											product={product}
											recommendedProductHandle={
												recommendedProductHandle
											}
											recommendedTag={recommendedTag}
											recommendedText={recommendedText}
											recommendedTextMobile={
												recommendedTextMobile
											}
											setRecommended={setRecommended}
											isRecommendedSelected={
												isRecommendedSelected
											}
											setRecommendedSelected={
												setRecommendedSelected
											}
										/>
									)}
								<Button
									product={product}
									disabled={!activeFirstLevel}
									isActiveClass={activeFirstLevel}
									isRecommendedSelected={
										isRecommendedSelected
									}
									recommendedProduct={recommendedProduct}
									isBundlePdp
									variantId={
										activeFirstLevel &&
										activeFirstLevel.storefrontId
									}
									availableForSale={availableForSale}
								/>
							</>
						) : (
							<>
								{!!canSubscribe && (
									<DeliveryRadio
										activeRadio={activeRadio}
										setActiveRadio={setActiveRadio}
										classname='pdp-miracle-radios'
										product={product}
										levelValidator={levelValidator}
										setErrorSelectLevel={
											setErrorSelectLevel
										}
									/>
								)}
								<div className='flex align-items'>
									<ProductMiracleActions
										{...{
											product,
											canSubscribe,
											discountPrice,
											optionsObjects,
											selectedValue,
											setSelectedValue,
											sellingPlanGroup,
											activeRadio,
											activeSecondLevel,
											activeThirdLevel,
											activeFiveLevel,
											recommendedProduct,
											isRecommendedSelected,
											levelValidator,
											setErrorSelectLevel,
										}}
										origin='desktop'
									/>
								</div>
							</>
						)}
					</>
				) : (
					<Button
						product={product}
						disabled={!activeFirstLevel}
						isActiveClass={activeFirstLevel}
						isRecommendedSelected={isRecommendedSelected}
						recommendedProduct={recommendedProduct}
						isBundlePdp
						variantId={
							activeFirstLevel && activeFirstLevel.storefrontId
						}
						availableForSale={availableForSale}
					/>
				)}
			</div>
		</div>
	);
};

export default ProductSelectionLevels;
