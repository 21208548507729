import React from 'react';
import { graphql } from 'gatsby';
import { Configure, InstantSearch } from 'react-instantsearch';
import algoliasearch from 'algoliasearch/lite';

import './Search.scss';
import Search from './Search';
import SEO from '../../components/SEO';

const SearchContainer = ({ data, location }) => {
	const allProducts = data?.allShopifyProduct?.edges?.map(
		(product) => product.node
	);
	const bestSellersProducts = data?.shopifyCollection.products;

	const searchClient = algoliasearch(
		'5V6H94NGY1',
		'349e7c5c16d05e2a3c2f5bf5864c8324'
	);

	return (
		<>
			<SEO location={location} />
			<InstantSearch
				searchClient={searchClient}
				indexName='shopify_products'
			>
				<Configure hitsPerPage={8} />
				<Search
					allProducts={allProducts}
					featuredProducts={bestSellersProducts}
				/>
			</InstantSearch>
		</>
	);
};

export const query = graphql`
	{
		allShopifyProduct {
			edges {
				node {
					...ProductCard
				}
			}
		}
		shopifyCollection(handle: { eq: "best-selling-products" }) {
			products {
				...ProductCard
			}
		}
	}
`;

export default SearchContainer;
