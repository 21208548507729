// External
import React, { useContext } from 'react';
import cn from 'classnames';

// Internal
import { StoreContext } from '../../../../../../../context/store-context';
import './ProductMiracleButton.scss';
import { getMetafield } from '../../../../../../../utils/helpers';

const ProductMiracleButton = ({
	product,
	oneTime,
	activeSecondLevel,
	activeThirdLevel,
	activeFiveLevel,
	recommendedProduct,
	isRecommendedSelected,
	setActiveModal,
	canSubscribe,
	activeRadio,
	selectedValue,
	sellingPlanGroup,
	levelValidator,
	setErrorSelectLevel,
	className,
}) => {
	const { addVariantToCart, addMultiVariantsToCart, setNotificationText } =
		useContext(StoreContext);

	const classes = cn('button button--dark', {
		'pdp-miracle-button--full': oneTime,
		'pdp-miracle-button': !className,
		'pdp-miracle-button-mobile': className === 'mobile',
	});
	const isRecommended = recommendedProduct && isRecommendedSelected;
	const isMiracleSystemExpert = getMetafield(
		product,
		'is_miracle_system_expert'
	);
	const isMiracleSystemUltimate = getMetafield(
		product,
		'is_miracle_system_ultimate'
	);

	const handleSubmit = () => {
		if (typeof levelValidator === 'object') {
			const errors = {
				errorLevelOne: '',
				errorLevelTwo: '',
				errorLevelThree: '',
				errorLevelFour: '',
				errorLevelFive: '',
			};

			if (!levelValidator.isValidOne) {
				errors.errorLevelOne =
					'Please select colour and tone before “Add to bag”';
			} else if (!levelValidator.isValidTwo) {
				errors.errorLevelTwo = 'Please select tone before “Add to bag”';
			} else if (
				isMiracleSystemExpert === 'true' ||
				isMiracleSystemUltimate === 'true'
			) {
				if (!levelValidator.isValidThree) {
					errors.errorLevelThree =
						'Please select a gloss before “Add to bag”';
				} else if (isMiracleSystemUltimate === 'true') {
					if (!levelValidator.isValidFour) {
						errors.errorLevelFour =
							'Please select a color smudge before “Add to bag”';
					} else if (!levelValidator.isValidFive) {
						errors.errorLevelFive =
							'Please select a color blending before “Add to bag”';
					}
				}
			}

			if (Object.values(errors).some((error) => error)) {
				setErrorSelectLevel && setErrorSelectLevel(errors);
				return;
			}
		}

		const { storefrontId, title } =
			activeSecondLevel !== 0
				? product.variants.find(
						(el) =>
							el.storefrontId === activeSecondLevel.storefrontId
				  )
				: product;

		const sellingPlanId =
			canSubscribe && activeRadio === 'auto-delivery'
				? sellingPlanGroup.sellingPlans.edges[selectedValue].node.id
				: null;
		const multiplyItems = isRecommended && [
			{
				variantId: storefrontId,
				quantity: 1,
				attributes: [],
				sellingPlanId,
			},
			{
				variantId: recommendedProduct.id,
				quantity: 1,
				attributes: [],
			},
		];

		isRecommended
			? addMultiVariantsToCart(multiplyItems)
			: activeSecondLevel !== 0
			? addVariantToCart(
					activeSecondLevel.storefrontId,
					1,
					[],
					false,
					product,
					sellingPlanId
			  )
			: addVariantToCart(
					product.variants[0].storefrontId,
					1,
					[],
					false,
					product,
					sellingPlanId
			  );
		setNotificationText(`${product.title} - ${title}`);
		setActiveModal && setActiveModal(false);
	};

	const getButtonStyle = (level) => ({
		backgroundColor: level === null ? '#E8E8E8' : className !== 'mobile' ? 'black': '#d67090',
	});

	const getSpanStyle = (level) => ({
		color: level === null ? '#5C5C5C' : 'white',
	});

	const renderButton = (level) => (
		<button
			className={classes}
			style={getButtonStyle(level)}
			onClick={handleSubmit}
		>
			<span style={getSpanStyle(level)}>add to bag</span>
		</button>
	);

	const level =
		isMiracleSystemUltimate === 'true'
			? activeFiveLevel
			: isMiracleSystemExpert === 'true'
			? activeThirdLevel
			: activeSecondLevel;

	return renderButton(level);
};

export default ProductMiracleButton;
