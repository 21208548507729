/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import {
	Configure,
	Hits,
	Index,
	useInstantSearch,
	useSearchBox,
	useInfiniteHits,
} from 'react-instantsearch';

import { getMetafield, getQuery } from '../../utils/helpers';
import ProductsListGrid from '../collections/ProductsListGrid';
import NoResultsBoundary from '../../components/Search/NoResultBoundary';
import BlogGridPageItem from '../blogs/BlogPageGrid/BlogPageGrigItem';
import ExpertsGridItem from '../ExpertsPage/ExpertsGrid/ExpertsGridItem/ExpertsGridItem';
import SalonGridItem from '../../components/Search/SalonGridItem';
import Loader from '../../components/Loader';

const Search = ({ allProducts, featuredProducts }) => {
	const searchParameter = decodeURIComponent(getQuery('filter') ?? '');

	const { results, status } = useInstantSearch();
	const { query, hits } = results;
	const { refine } = useSearchBox();

	const { showMore, isLastPage, hits: hitsResult } = useInfiniteHits();
	const [isLoading, setIsLoading] = useState(true);

	const productsHandles = hits?.map((product) => product.handle);
	const productsToShow = allProducts
		.filter((product) => {
			if (!productsHandles?.includes(product.handle)) return false;
			const isHideProductInSearch = getMetafield(
				product,
				'hide_product_from_search_results'
			);
			if (isHideProductInSearch) return isHideProductInSearch === 'false';
			return true;
		})
		?.sort(
			(product1, product2) =>
				productsHandles.indexOf(product1.handle) -
				productsHandles.indexOf(product2.handle)
		);

	const productsToShowLength = productsToShow?.length;
	const resultText = searchParameter
		? productsToShow.length === 1
			? `1 Result for "${searchParameter ?? ''}"`
			: `${hitsResult.length} Results for "${searchParameter ?? ''}"`
		: `0 Results for ""`;

	useEffect(() => {
		if (isLoading) {
			setIsLoading(false);
		}
	}, [hits]);

	const handleShowMore = () => {
		setIsLoading(true);
		showMore();
	};

	useEffect(() => {
		if (searchParameter && query !== searchParameter) {
			refine(searchParameter);
		}
	}, [searchParameter]);

	if (searchParameter !== '' && query !== searchParameter) {
		return <Loader isFullScreen />;
	}

	return (
		<div className='search-page-container'>
			<h2>{resultText}</h2>
			{(!searchParameter || productsToShow?.length === 0) && (
				<p>Check the spelling or try a more general term.</p>
			)}
			{searchParameter && productsToShow?.length > 0 && (
				<>
					<ProductsListGrid
						productsList={productsToShow}
						isLoading={isLoading}
						isSearchView
					/>

					{showMore && !isLastPage && productsToShowLength >= 8 && (
						<div className='search-button-more'>
							<button
								onClick={handleShowMore}
								disabled={isLoading || isLastPage}
							>
								Show more products
							</button>
						</div>
					)}
				</>
			)}

			{(!searchParameter || productsToShowLength === 0) && (
				<>
					<h2>Featured products</h2>
					<ProductsListGrid productsList={featuredProducts} />
				</>
			)}

			<Index indexName='shopify_articles'>
				<Configure hitsPerPage={3} page={0} />
				<NoResultsBoundary fallback={null}>
					<h2>Blogs</h2>

					<Hits
						hitComponent={({ hit }, index) => (
							<BlogGridPageItem article={hit} key={index} />
						)}
						classNames={{
							list: 'blog-grid',
						}}
					/>
				</NoResultsBoundary>
			</Index>

			<Index indexName='manual_salon_page'>
				<Configure hitsPerPage={3} page={0} />
				<NoResultsBoundary fallback={null}>
					<h2>Salon Pages</h2>
					<Hits
						hitComponent={({ hit }, index) => (
							<SalonGridItem salon={hit} />
						)}
						classNames={{
							list: 'blog-grid',
						}}
					/>
				</NoResultsBoundary>
			</Index>

			<Index indexName='manual_shopify_experts'>
				<Configure hitsPerPage={6} page={0} />
				<NoResultsBoundary fallback={null}>
					<h2>Colour and Styling Experts</h2>
					<Hits
						hitComponent={({ hit }, index) => (
							<ExpertsGridItem expert={hit} key={index} />
						)}
						classNames={{
							list: 'blog-grid',
						}}
					/>
				</NoResultsBoundary>
			</Index>
		</div>
	);
};

export default Search;
