import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import BlogPageTags from '../BlogPageTags/BlogPageTags';

import './BlogPageGridItem.scss';
import LocalImageOptimized from '../../../../components/LocalImage/LocalImageOptimized';


const BlogGridPageItem = ({ article, withoutTags, classname }) => {
	const blogLink = article?.storyblok_blog
		? `/${article.full_slug}`
		: `/blogs/community/${article.handle}`;
	return (
		<div className={`blog-grid-item ${classname && classname}`}>
			<Link to={blogLink}>
				<div className='blog-grid-item__img'>
					{article?.storyblok_blog ? (
						<LocalImageOptimized
							imageUrl={article.image}
							alt={article.imageAlt}
							loadingType='eager'
						/>
					) : (
						<LazyLoadImage
							src={article.image?.originalSrc || article.image}
							alt={article.title}
							effect='blur'
							wrapperClassName='lazyLoad-wrapper-img'
						/>
					)}
				</div>
				{!withoutTags && article.tags && article.tags.length > 0 && (
					<BlogPageTags tags={article.tags} />
				)}
				<h3 className='blog-grid-item__title'>{article.title}</h3>
			</Link>
		</div>
	);
};

export default BlogGridPageItem;
