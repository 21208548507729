// External
import React, { useContext, useEffect, useState } from 'react';

// Internal
import ProductInfoCollection from './ProductInfoCollection';
import ProductInfoTitle from './ProductInfoTitle';
import ProductInfoPrice from './ProductInfoPrice';
import ProductInfoDescription from './ProductInfoDescription';
import ProductAdvantages from '../ProductAdvantages';
import ProductRecommended from '../ProductRecommended';
import ShopTheLook from '../ShopTheLook';
import ProductTooltip from '../ProductTooltip';
import ProductBundlesSection from '../ProductBundlesSection';
import ProductVariantSelector from './ProductVariantSelector';
import { StoreContext } from '../../../../context/store-context';
import { useWindowWidth } from '../../../../utils/hooks';
import {
	getDiscountPrice,
	getMetafield,
	getSellingPlanGroupDefaultValue,
	getSellingPlanGroupOptions,
	isAvailable,
} from '../../../../utils/helpers';
import './ProductInfo.scss';
import Button from '../../../../components/Button';
import ProductMiracleButton from '../ProductBundlesSection/ProductSelectionLevels/ProductMiracleActions/ProductMiracleButton';

const ProductInfo = ({
	product,
	cart,
	collections,
	shopLookProductsHandles,
	recommendedProduct,
	recommendedProductHandle,
	isRecommendedSelected,
	windowWidth,
	rating,
	sellingPlanGroup,
	miracleSystem,
	activeVariant,
	setActiveVariant,
	scrollToReviews,
	productsList,
	allBaseColours,
	withShadeShot,
	shots,
	shadeShotsProducts,
	isDiscountGroup,
}) => {
	const { subscriptionsEnabled, yotpoRatings } = useContext(StoreContext);

	const isSubscribeOnPDP = getMetafield(product, 'subscription_on_pdp');
	const [groupFound, setGroupFound] = useState(
		Object.keys(sellingPlanGroup).length > 0
	);
	const [canSubscribe, setCanSubscribe] = useState(
		groupFound && subscriptionsEnabled && isSubscribeOnPDP
	);
	const [options, setOptions] = useState(
		canSubscribe
			? getSellingPlanGroupOptions(sellingPlanGroup.sellingPlans.edges)
			: []
	);
	const [activeRadio, setActiveRadio] = useState(
		canSubscribe ? 'auto-delivery' : 'One-Time Purchase'
	);
	const [selectedValue, setSelectedValue] = useState(
		canSubscribe ? getSellingPlanGroupDefaultValue(options) : null
	);
	const [discountPrice, setDiscountPrice] = useState(
		product.variants[0].price
	);
	const [discountPercentageWithSub, setDiscountPercentageWithSub] =
		useState(0);
	const width = useWindowWidth();
	const isMiracle = getMetafield(product, 'is_miracle_system');
	const isGiftCard =
		product.handle.includes('gift') && !product.handle.includes('comb');
	const optionsObjects = options.map((opt, i) => ({
		value: i,
		label: `Every ${opt} ${opt === '4 Weeks' ? '(most common)' : ''}`,
	}));

	const isVariant = JSON.parse(getMetafield(product, 'is_variant'));
	const isPermHairColourWithVariants = JSON.parse(
		getMetafield(product, 'is_perm_hair_colour_with_variants')
	);

	const [selectedVariant, setSelectedVariant] = useState(undefined);

	const isMobile = width < 550;

	useEffect(() => {
		const handleFirstVariant = () => {
			if (!product || !isVariant) return null;
			setSelectedVariant(product.variants[0]);
			setActiveVariant(product.variants[0]);
		};
		handleFirstVariant();
	}, [product, isVariant]);

	useEffect(() => {
		const handleSelectedVariant = () => {
			if (!selectedVariant || !isVariant) return;
			const targetDiv = document.querySelectorAll(
				'.target-variant__product'
			);
			for (const variant of targetDiv) {
				if (variant.getAttribute('data-id') === selectedVariant.id) {
					variant.style.border = '3px solid pink';
				} else {
					variant.style.border = '3px solid transparent';
				}
			}
		};
		handleSelectedVariant();
	}, [selectedVariant, isVariant]);

	useEffect(() => {
		if (canSubscribe) {
			const plan = sellingPlanGroup.sellingPlans.edges[selectedValue];
			const productPrice = parseInt(product?.variants[0].price);
			const discountPercentage =
				plan?.node.pricingPolicies[0].adjustmentValue.percentage;
			const discount = getDiscountPrice(productPrice, discountPercentage);
			setDiscountPercentageWithSub(discountPercentage);
			setDiscountPrice(discount);
		}
	}, [canSubscribe]);

	return (
		<div className='pdp-info'>
			<ProductInfoCollection
				product={product}
				collections={collections}
			/>
			{!isGiftCard && (
				/* eslint-disable */
				<>
					<ProductInfoTitle
						product={product}
						productsList={productsList}
						allBaseColours={allBaseColours}
						withShadeShot={withShadeShot}
						shots={shots}
						shadeShotsProducts={shadeShotsProducts}
						sellingPlanGroup={sellingPlanGroup}
						miracleSystem={miracleSystem}
					/>
					<ProductInfoPrice
						activeRadio={activeRadio}
						isDiscountGroup={isDiscountGroup}
						discountPrice={discountPrice}
						canSubscribe={canSubscribe}
						cart={cart}
						product={product}
						rating={rating}
						isRecommendedSelected={isRecommendedSelected}
						recommendedProduct={recommendedProduct}
						scrollToReviews={scrollToReviews}
						discountPercentageWithSub={discountPercentageWithSub}
						selectedVariant={selectedVariant}
					/>
					<ProductInfoDescription product={product} />
					{isMobile && !isMiracle && (
						<div className='button-action-pdp'>
							<Button
								buttonText='Add to bag'
								product={product}
								isLink={false}
								availableForSale={isAvailable(product)}
								isPinkBasket
								isButtonCart
							/>
						</div>
					)}
					<ProductAdvantages product={product} />
					{product &&
						isVariant &&
						selectedVariant &&
						product?.totalInventory > 0 && (
							<ProductVariantSelector
								product={product}
								selectedVariant={selectedVariant}
								setSelectedVariant={setSelectedVariant}
								setActiveVariant={setActiveVariant}
								isPermamentHairColour={
									isPermHairColourWithVariants
								}
							/>
						)}
				</>
			)}

			{recommendedProductHandle && windowWidth <= 991 && (
				<ProductRecommended {...props} />
			)}
			{recommendedProductHandle && windowWidth <= 991 && (
				<ProductRecommended
					{...{
						product,
						cart,
						collections,
						shopLookProductsHandles,
						recommendedProduct,
						recommendedProductHandle,
						isRecommendedSelected,
						windowWidth,
						rating,
					}}
				/>
			)}
			{shopLookProductsHandles && (
				<ShopTheLook
					shopLookProductsHandles={shopLookProductsHandles.split('|')}
				/>
			)}
			<ProductBundlesSection
				{...{
					product,
					cart,
					collections,
					shopLookProductsHandles,
					recommendedProduct,
					recommendedProductHandle,
					isRecommendedSelected,
					windowWidth,
					rating,
					canSubscribe,
					subscriptionsEnabled,
					discountPrice,
					activeRadio,
					setActiveRadio,
					selectedValue,
					setSelectedValue,
					sellingPlanGroup,
					optionsObjects,
					activeVariant,
					setActiveVariant,
					isGiftCard,
					discountPercentageWithSub,
					selectedVariant,
				}}
				options={options}
			/>
			{!canSubscribe ? null : (
				<ProductTooltip product={product} isMiracle={isMiracle} />
			)}
		</div>
	);
};

export default ProductInfo;
