// External
import React, { useContext, useEffect, useState, Suspense, lazy } from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';
import { GatsbyImage } from 'gatsby-plugin-image';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import Button from '../Button';
import LocalImage from '../LocalImage';
import AnotherListItem from './AnotherListItem';
import AnotherBigListItem from './AnotherBigListItem';
import RatingStars from '../Rating/RatingStars';
import Klaviyo from '../Klaviyo';
import Loader from '../Loader';
import { StoreContext } from '../../context/store-context';

import {
	getMetafield,
	getPrice,
	getDiscountPrice,
	sendProductClickEvent,
} from '../../utils/helpers';
import getProductRating from '../../helpers/getProductRating';

import './ProductListItem.scss';
import isAWholeNumber from '../../helpers/isAWholeNumber';

const Yotpo = lazy(() => import('../Yotpo'));

const ProductListItem = (props) => {
	const { subscriptionsEnabled, yotpoRatings } = useContext(StoreContext);
	const [groupFound, setGroupFound] = useState(
		props.sellingPlanGroup && Object.keys(props.sellingPlanGroup).length
	);
	const [discountPrice, setDiscountPrice] = useState(
		props.product.variants[0].price
	);
	const [isBundleVariant, setIsBundleVariant] = useState(
		!props.sellingPlanGroup && props.product.handle.includes('bundle')
	);
	const [isBundle, setIsBundle] = useState(false);
	const [priceValue, setPriceValue] = useState('');
	const isMiracle = getMetafield(props.product, 'is_miracle_system');
	const isSubscribeOnPDP = getMetafield(props.product, 'subscription_on_pdp');
	const [canSubscribe, setCanSubscribe] = useState(
		groupFound && subscriptionsEnabled && isSubscribeOnPDP
	);
	const isCustomizeAndPDP = getMetafield(
		props.product,
		'is_customize_and_pdp'
	);
	const showRating = getMetafield(props.product, 'is_rating_info');
	const ratingText = getMetafield(props.product, 'text_rating_info');
	const ratingColor = getMetafield(props.product, 'rating_color');
	const isNotProduct = getMetafield(props.product, 'is_not_product');
	const isBigElement = getMetafield(props.product, 'is_big_element');
	const isKlaviyo = props.product.handle === 'banner-klaviyo';
	const customPreviewImage = getMetafield(
		props.product,
		'custom_preview_image'
	);
	const isMiracleSystemExpert = getMetafield(
		props.product,
		'is_miracle_system_expert'
	);
	const isMiracleSystemUltimate = getMetafield(
		props.product,
		'is_miracle_system_ultimate'
	);

	const productNavigation = getMetafield(
		props.product,
		'type_of_navigation_a_b_test'
	);

	const productHref = !productNavigation
		? `/products/${props.product.handle}`
		: `https://joshwoodcolour.com/products/${props.product.handle}`;

	const productRating = getProductRating(props.product, yotpoRatings);
	const reviewsAverage = productRating?.product_score;
	const reviewsCount = productRating?.total_reviews;

	function getPriceProduct() {
		if (isBundleVariant) {
			return props.product;
		}

		return isMiracle
			? props.product.variants[0]
			: props.product.variants.filter(
					(variant) =>
						!variant.title.toLowerCase().includes('miracle')
			  )[0];
	}

	const [price, setPrice] = useState(getPriceProduct());

	useEffect(() => {
		if (props.sellingPlanGroup) {
			setGroupFound(Object.keys(props.sellingPlanGroup).length);
		}
	}, [props.sellingPlanGroup]);

	useEffect(() => {
		setCanSubscribe(groupFound && subscriptionsEnabled && isSubscribeOnPDP);
	}, [groupFound]);

	useEffect(() => {
		(props.product.options || []).forEach((option) => {
			const name = option.name.toLowerCase();
			if (name === 'bundle') setIsBundle(true);
		});
	}, []);

	useEffect(() => {
		if (canSubscribe) {
			const plan = props.sellingPlanGroup.sellingPlans.edges[0],
				productPrice = parseInt(props.product.variants[0].price),
				discount = getDiscountPrice(
					productPrice,
					plan.node.pricingPolicies[0].adjustmentValue.percentage
				);
			setDiscountPrice(discount);
		}
	}, [canSubscribe]);

	useEffect(() => {
		setPrice(getPriceProduct());
	}, [isBundle, isMiracle]);

	useEffect(() => {
		const priceIsWholeNumber = isAWholeNumber(price.price);
		setPriceValue(
			isSubscribeOnPDP
				? `£${
						isAWholeNumber(discountPrice)
							? parseInt(discountPrice)
							: discountPrice
				  }`
				: `£${priceIsWholeNumber ? parseInt(price.price) : price.price}`
		);
	}, [discountPrice, price, props.product]);

	const adjustPrice = () => {
		const priceOfProduct = price[canSubscribe ? 'price' : 'compareAtPrice'];
		const priceIsWholeNumber = isAWholeNumber(priceOfProduct);

		return priceIsWholeNumber ? 0 : 2;
	};

	const isCrossPriceInBundles =
		(!isMiracle && !canSubscribe && 'original-product-price--cross') ||
		((isMiracleSystemExpert || isMiracleSystemUltimate || !canSubscribe) &&
			'original-product-price--cross');

	return (
		<>
			{isKlaviyo ? (
				<div className='products-list-grid__klaviyo'>
					<Klaviyo id={process.env.KLAVIYO_PLP_GRID_FORM_ID} />
				</div>
			) : isNotProduct && isBigElement ? (
				<AnotherBigListItem product={props.product} />
			) : isNotProduct ? (
				<AnotherListItem product={props.product} />
			) : (
				!props.isShot && (
					<li
						className={cn(
							` product-list-item ${
								props.classname && props.classname
							} ${
								props.isHoverButton &&
								'product-list-item--hover'
							}`
						)}
					>
						<Link
							to={productHref}
							onClick={() => sendProductClickEvent(props.product)}
						>
							{showRating && ratingText && (
								<span
									style={{
										backgroundColor:
											ratingColor ||
											'rgba(236, 186, 86, 0.5)',
									}}
									className='pdp-info__rating'
								>
									{ratingText}
								</span>
							)}
							<>
								{customPreviewImage ? (
									<div className='product-list-item__img'>
										<LazyLoadImage
											src={customPreviewImage}
											alt='product list item preview'
											effect='blur'
										/>
									</div>
								) : (
									<div className='product-list-item__img'>
										{props.product.media[0] ? (
											<>
												{props.product.media[0].preview
													.image.gatsbyImageData ? (
													<GatsbyImage
														alt={
															props.product.title
														}
														image={
															props.product
																.media[0]
																.preview.image
																.gatsbyImageData
														}
													/>
												) : (
													<LazyLoadImage
														src={
															props.product
																.media[0]
																.preview.image
																.originalSrc
														}
														alt={
															props.product.title
														}
														effect='blur'
													/>
												)}
											</>
										) : (
											<LocalImage
												image='no-image.jpg'
												alt={props.product.title}
											/>
										)}
									</div>
								)}
							</>
						</Link>
						<div className='product-list-item__info'>
							<Link
								to={productHref}
								onClick={() =>
									sendProductClickEvent(props.product)
								}
							>
								<h3>{props.product.title}</h3>
							</Link>
							<div className='product-list-item__rating'>
								{reviewsAverage && reviewsCount ? (
									<RatingStars
										rating={reviewsAverage}
										count={reviewsCount}
									/>
								) : (
									<p>No reviews yet</p>
								)}
							</div>
							<div className='product-list-item__price flex'>
								{!props.isShot &&
								props.product.variants &&
								parseInt(price.price) ? (
									<>
										{price.compareAtPrice ||
										canSubscribe ? (
											<div
												className={`original-product-price ${isCrossPriceInBundles}`}
											>
												{getPrice(
													price[
														canSubscribe
															? 'price'
															: 'compareAtPrice'
													],
													props.cart,
													adjustPrice()
												)}
												{canSubscribe ? (
													<span className='current-product-price__text'>
														single purchase or{' '}
														<br />
														<strong>
															{priceValue}
														</strong>{' '}
														Subscribe & Save
													</span>
												) : null}
											</div>
										) : null}
										<p
											className={`current-product-price ${
												(price.compareAtPrice ||
													canSubscribe) &&
												'current-product-price--red'
											}`}
										>
											{(!isMiracle ||
												(isMiracle && !canSubscribe)) &&
												!canSubscribe &&
												priceValue}
										</p>
									</>
								) : null}
								{!isMiracle && props.product.rating && (
									<Suspense fallback={<Loader />}>
										<Yotpo rating={props.product.rating} />
									</Suspense>
								)}
							</div>
							{!props.withoutButton && !props.isShot && (
								<Button
									buttonText={
										isMiracle ||
										isCustomizeAndPDP ||
										canSubscribe
											? 'customise'
											: 'add to bag'
									}
									product={props.product}
									className='product-list-item__button'
									isLink={
										isMiracle ||
										isCustomizeAndPDP ||
										canSubscribe
									}
									linkUrl={
										(isMiracle ||
											isCustomizeAndPDP ||
											canSubscribe) &&
										productHref
									}
									isLight
									isBundle={isBundle}
									availableForSale={props.available}
									isPlp
									plpVersion='1'
								/>
							)}
						</div>
					</li>
				)
			)}
		</>
	);
};
export default ProductListItem;
